import {
  EAnimalPetChargeStatusDB,
  EAnimalPetChargeStatusUI,
  EVerificationStatus,
  EVerificationStatusUi,
  TEditingBooleanFieldUi,
  TEditingFieldUi,
  TFail,
  TFailUI,
  TPropertyInfoUI,
  TPropertyManagementInfoUI,
  TSignatureType,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TStatusInfoWithSourceDB,
  TStatusInfoWithSourceUI,
} from './GeneralTypes';
import { TDocPacketGet, TDocPacketGetUI } from './DocPacket.types';
import {
  EAnimalStatusDB,
  EAnimalStatusUI,
  EAnimalTypes,
  EAnimalTypesUI,
  ENotBeingChargedReasonDB,
  ENotBeingChargedReasonUI,
} from './Animal.types';

import {
  EAnimalTagDB,
  EAnimalTagUI,
  ETenantTagDB,
  ETenantTagUI,
} from './Tags.types';
import {
  TApplicationHPPacket,
  TApplicationHPPacketUI,
} from './Applications.types';

export type TTenantSearchListDB = {
  id: string;
  name: string;
  profilePicture: string;
  email: string;
};

export type TTenantSearchListUI = {
  label: string;
  value: string;
};

export enum ETenantDetailsStatusDB {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  RETRACTED = 'RETRACTED',
  MOVED_IN = 'MOVED_IN',
  MOVED_OUT = 'MOVED_OUT',
  NOTICE = 'NOTICE',
}

export enum ETenantDetailsStatusUI {
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  RETRACTED = 'Retracted',
  MOVED_IN = 'Moved in',
  MOVED_OUT = 'Former Resident',
  NOTICE = 'Notice',
}
export enum ETrainingStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
}
export enum ETrainingStatusUi {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
}
export enum EPaymentStatus {
  COMPLETE = 'COMPLETE',
  NOT_REQUIRED = 'NOT_REQUIRED',
  INCOMPLETE = 'INCOMPLETE',
}
export enum EPaymentStatusUi {
  COMPLETE = 'COMPLETE',
  NOT_REQUIRED = 'NOT_REQUIRED',
  INCOMPLETE = 'INCOMPLETE',
}

export type TTraining = {
  title: string;
  status: ETrainingStatus;
};

export type TTrainingUi = {
  title: string;
  status: ETrainingStatusUi;
};
export type TProfileDetailsUI = {
  id: string;
  name: TEditingFieldUi;
  email: TEditingFieldUi;
  phone: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  haveAnimal: TEditingBooleanFieldUi;
  hpDocumentPackets?: TDocPacketGetUI[];
  trainings: TTrainingUi[];
  paymentStatus: EPaymentStatusUi;
};

export type TProfileDetails = {
  name: string;
  phone: string;
  email: string;
  profilePicture: string;
  haveAnimal: boolean;
  userId: string;
  hpDocumentPackets?: TDocPacketGet[];
  trainings: TTraining[];
  paymentStatus: EPaymentStatus;
};

export type TAnimalMetadataUI = {
  present: boolean;
  description: string | null;
} | null;

export type TAnimalMetadata = {
  present: boolean;
  description: string | null;
} | null;

export type TApplicationProfileUI = Omit<
  TProfileDetailsUI,
  'hpDocumentPackets'
> & {
  fails: TFailUI[];
  documentsFails: TFailUI[];
  hpDocumentPackets: TApplicationHPPacketUI[];
  animalMetadata: TAnimalMetadataUI;
};
export type TApplicationProfile = Omit<TProfileDetails, 'hpDocumentPackets'> & {
  fails: TFail;
  hpDocumentPackets: TApplicationHPPacket[];
  specifiedAnimalMetadata: TAnimalMetadata;
};

export type TSaMetadataUpdateUI = {
  animalId: string;
  disabilityType: 'observable' | 'nonObservable';
};
export type TSaMetadataUpdate = {
  animalId: string;
  trainedForObservableDisability: boolean;
};

export type TEsaMetadataUpdateUI = {
  animalId: string;
  hasEsa: 'yes' | 'no';
  description?: string | null;
};
export type TEsaMetadataUpdate = {
  animalId: string;
  hasEsaLetterForAnimal: boolean;
  description?: string | null;
};

export enum EPetStatusDB {
  HAS_ANIMAL = 'HAS_ANIMAL',
  HAS_NOT_ANIMAL = 'HAS_NOT_ANIMAL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum EPetStatusUI {
  HAS_ANIMAL = 'Yes',
  HAS_NOT_ANIMAL = 'No',
  NOT_SPECIFIED = 'N/A',
}
export enum EResidencyStateDB {
  APPLICANT = 'APPLICANT',
  RESIDENT = 'RESIDENT',
  NOTICE = 'NOTICE',
  NONE = 'UNDEFINED',
  FUTURE = 'FUTURE',
}

export enum EPetQuantityFilterUI {
  ZERO = '0',
  MORE_THAN_ZERO = 'More than 0',
}

export enum EPetQuantityFilterDB {
  ZERO = 'ZERO',
  MORE_THAN_ZERO = 'MORE_THAN_ZERO',
}
export enum EResidencyStateUI {
  APPLICANT = 'Applicant',
  RESIDENT = 'Resident',
  NOTICE = 'Noticed',
  NONE = 'None',
  FUTURE = 'Future Resident',
}
export type TTenantInfoDB = {
  tenantId: string;
  profilePicture: string | null;
  name: string;
};
export type TTenantInfoUI = {
  tenantId: string;
  tenantName: string;
  profilePicture: string | null;
  firstLetters: string;
};

export type TPetInfoDB = {
  petStatus: EPetStatusDB;
  petsCount: number;
};

export type TPetInfoUI = {
  petStatus: EPetStatusUI;
  petsCount: number;
};

export type TTenantDB = {
  tenantId: string;
  profilePicture: string | null;
  name: string;
  phone: string | null;
  email: string | null;
  isUsernameEmail: boolean;
  notificationsEnabled: boolean;
  petStatus: EPetStatusDB;
  petsCount: number;
  state: EResidencyStateDB;
  propertyId: string | null;
  propertyName: string | null;
  propertyManagementId: string | null;
  propertyManagementName: string | null;
  propertyManagementLogo: string | null;
  statusChangeInfo: TStatusInfoWithSourceDB | null;
  unitNumber: string | null;
};
export type TTenantUI = {
  tenantInfo: TTenantInfoUI;
  phone: string | null;
  email: string | null;
  notificationsEnabled: boolean;
  isUsernameEmail: boolean;
  residencyState: EResidencyStateUI;
  petInfo: TPetInfoUI;
  propertyInfo: TPropertyInfoUI | string;
  propertyManagementInfo: TPropertyManagementInfoUI | string;
  statusChangeInfo: TStatusInfoWithSourceUI | null;
  unitNumber: string | null;
};

export type TTenantAnimalDataDB = {
  canTagKnown: boolean;
  chargingStatusInfo: TStatusInfoWithSourceDB | null;
  animalId: string;
  isToPetConverted: boolean;
  breed: { value: string } | null;
  chargingStatusChangeReason: null | string;
  chargingStatus: EAnimalPetChargeStatusDB;
  id: string;
  name: string;
  notBeingChargedReason: ENotBeingChargedReasonDB | null;
  specie: { value: string } | null;
  status: EAnimalStatusDB;
  tags: EAnimalTagDB[];
  type: EAnimalTypes;
  profilePicture: null | string;
  verificationStatus: EVerificationStatus;
  discoveredTagReason: string | null;
  policySignedBy: string | null;
  policyDateSigned: string | null;
  policyId: string | null;
  policyContent: string | null;
  policyName: string;
  statusChangeInfo: TStatusChangeInfoDB | null;
  signedDocType: TSignatureType | null;
};

export type TTenantAnimalDataUI = {
  chargingStatusInfo: TStatusInfoWithSourceUI | null;
  canTagKnown: boolean;
  animalId: string;
  profilePicture?: string;
  isToPetConverted: boolean;
  breed: string;
  chargingStatusChangeReason: null | string;
  id: string;
  name: string;
  notBeingChargedReason: ENotBeingChargedReasonUI | null;
  specie: string;
  status: EAnimalStatusUI;
  chargingStatus: EAnimalPetChargeStatusUI;
  tags: EAnimalTagUI[];
  type: EAnimalTypesUI;
  verificationStatus: EVerificationStatusUi;
  discoveredTagReason: string | null;
  policySignedBy: string | null;
  policyDateSigned: string | null;
  policyId: string | null;
  policyContent: string | null;
  policyName: string;
  statusChangeInfo: TStatusChangeInfoUI | null;
  signedDocType: TSignatureType | null;
};

export type TResidentDataDB = {
  email: string | null;
  id: string;
  leaseToDate: string;
  moveInDate: string;
  nextLeaseToDate: string | null;
  name: string;
  phone: string | null;
  profilePicture: string | null;
  status: ETenantDetailsStatusDB;
  animals: TTenantAnimalDataDB[];
  hasAnimal: boolean | null;
  roommateHasAnimal: boolean | null;
  tags: ETenantTagDB[];
  statusChangeInfo: TStatusChangeInfoDB | null;
};

export type TResidentDataUI = {
  email: string | null;
  id: string;
  leaseToDate: string;
  moveInDate: string;
  nextLeaseToDate: string | null;
  name: string;
  phone: string | null;
  profilePicture: string | null;
  status: ETenantDetailsStatusUI;
  animals: TTenantAnimalDataUI[];
  hasAnimal: boolean | null;
  roommateHasAnimal: boolean | null;
  tags: ETenantTagUI[];
  statusChangeInfo: TStatusInfoWithSourceUI | null;
};

export type TResidencyDataDB = {
  propertyManagementLogo: string | null;
  propertyManagementName: string;
  propertyName: string;
  roommateHasAnimal: null | boolean;
  hasAnimal: null | boolean;
  unitNumber: string;
  members: TResidentDataDB[];
};

export type TResidencyDataUI = {
  propertyManagementLogo: string | null;
  roommateHasAnimal: null | boolean;
  hasAnimal: null | boolean;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  roommates: number;
  [EAnimalTypesUI.PET]: number;
  [EAnimalTypesUI.SA]: number;
  [EAnimalTypesUI.ESA]: number;
  [EAnimalPetChargeStatusUI.SHOULD_NOT_BE_CHARGE]: number;
  [EAnimalPetChargeStatusUI.GRACE_PERIOD]: number;
  [EAnimalPetChargeStatusUI.BEING_CHARGED]: number;
  [EAnimalPetChargeStatusUI.NOT_BEING_CHARGED]: number;
  members: TResidentDataUI[];
};

export type TTenantDetailsDB = {
  email: string | null;
  id: string;
  name: string;
  notificationsEnabled: boolean;
  phone: string | null;
  residencies: TResidencyDataDB[];
};

export type TTenantDetailsUI = {
  id: string;
  email: string | null;
  name: string;
  notificationsEnabled: boolean;
  phone: string | null;
  residencies: TResidencyDataUI[];
};

export type TMarkTenantAsKnownDB = {
  tenantResidencyId: string;
};
export type TMarkTenantAsKnownUI = {
  tenantResidencyId: string;
};
