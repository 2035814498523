import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/Converter.types';
import {
  EPaymentStatus,
  EPaymentStatusUi,
  EPetStatusDB,
  EPetStatusUI,
  EResidencyStateDB,
  EResidencyStateUI,
  ETenantDetailsStatusDB,
  ETenantDetailsStatusUI,
  ETrainingStatus,
  ETrainingStatusUi,
  TAnimalMetadata,
  TAnimalMetadataUI,
  TApplicationProfile,
  TApplicationProfileUI,
  TMarkTenantAsKnownDB,
  TMarkTenantAsKnownUI,
  TPetInfoDB,
  TPetInfoUI,
  TResidencyDataDB,
  TResidencyDataUI,
  TResidentDataDB,
  TResidentDataUI,
  TTenantAnimalDataDB,
  TTenantAnimalDataUI,
  TTenantDB,
  TTenantDetailsDB,
  TTenantDetailsUI,
  TTenantInfoDB,
  TTenantInfoUI,
  TTenantSearchListDB,
  TTenantSearchListUI,
  TTenantUI,
  TTraining,
  TTrainingUi,
} from 'data/types/Tenant.types';

import { omit, pick } from 'lodash';
import {
  animalChargingStatusConverter,
  failsConvertor,
  filtersConverter,
  formattedDate,
  getFirstLetters,
  propertyInfoConverter,
  propertyManagementInfoConverter,
  readableBooleanFieldConvertor,
  readableFieldConvertor,
  statusChangeInfoConverter,
  statusInfoWithResourceConverter,
  verificationStatusConverter,
} from './General.converters';
import { getHPDocPacketsConvertor } from './DocPacket.convertors';
import {
  animalStatusConverter,
  animalTypesConvertor,
  notBeingChargedReasonConverter,
} from './Animal.converters';
import { tagConverter, tenantTagConverter } from './Tags.converters';
import {
  EAnimalPetChargeStatusDB,
  EAnimalPetChargeStatusUI,
} from '../types/GeneralTypes';
import { EAnimalTypes, EAnimalTypesUI } from '../types/Animal.types';

export const getTenantSearchListConvertor: TFetchConverter<
  TTenantSearchListUI[],
  TTenantSearchListDB[]
> = {
  fromDb: data =>
    data.map(item => ({
      label: item.name,
      value: item.id,
    })),
};
export const animalMetadataConvertor: TFetchConverter<
  TAnimalMetadataUI,
  TAnimalMetadata
> = {
  fromDb: data => {
    if (!data) {
      return null;
    }

    return {
      present: data.present,
      description: data?.description || null,
    };
  },
};

export const trainingStatusConvertor: TConvertor<
  ETrainingStatusUi,
  ETrainingStatus
> = {
  fromDb: data => {
    return ETrainingStatusUi[
      ETrainingStatus[data] as keyof typeof ETrainingStatusUi
    ];
  },
  toDb: data => {
    return ETrainingStatus[
      ETrainingStatusUi[data] as keyof typeof ETrainingStatus
    ];
  },
};

export const paymentStatusConvertor: TConvertor<
  EPaymentStatusUi,
  EPaymentStatus
> = {
  fromDb: data => {
    return EPaymentStatusUi[
      EPaymentStatus[data] as keyof typeof EPaymentStatusUi
    ];
  },
  toDb: data => {
    return EPaymentStatus[
      EPaymentStatusUi[data] as keyof typeof EPaymentStatus
    ];
  },
};

export const trainingConvertor: TFetchConverter<TTrainingUi, TTraining> = {
  fromDb: data => {
    return {
      title: data.title,
      status: trainingStatusConvertor.fromDb(data.status),
    };
  },
};
export const applicationProfileConvertor: TFetchConverter<
  TApplicationProfileUI,
  TApplicationProfile
> = {
  fromDb: data => {
    const documentsFails = pick(data.fails || {}, ['hpDocumentPackets']);
    return {
      id: data.userId,
      profilePicture: readableFieldConvertor.fromDb(data.profilePicture),
      name: readableFieldConvertor.fromDb(data.name),
      email: readableFieldConvertor.fromDb(data.email),
      phone: readableFieldConvertor.fromDb(data.phone),
      haveAnimal: readableBooleanFieldConvertor.fromDb(data.haveAnimal),
      fails: failsConvertor.fromDb(omit(data.fails || {}, 'hpDocumentPackets')),
      documentsFails: failsConvertor.fromDb(documentsFails),
      animalMetadata: animalMetadataConvertor.fromDb(
        data.specifiedAnimalMetadata,
      ),
      trainings: data.trainings.map(item => trainingConvertor.fromDb(item)),
      paymentStatus: paymentStatusConvertor.fromDb(data.paymentStatus),
      hpDocumentPackets: data.hpDocumentPackets?.map(packet => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
        fails: failsConvertor.fromDb(packet.fails || {}),
      })),
    };
  },
};

export const tenantResidencyStateConverter: TConvertor<
  EResidencyStateUI,
  EResidencyStateDB
> = {
  fromDb: data => {
    const statuses: {
      [key in EResidencyStateDB]: EResidencyStateUI;
    } = {
      [EResidencyStateDB.NONE]: EResidencyStateUI.NONE,
      [EResidencyStateDB.APPLICANT]: EResidencyStateUI.APPLICANT,
      [EResidencyStateDB.RESIDENT]: EResidencyStateUI.RESIDENT,
      [EResidencyStateDB.FUTURE]: EResidencyStateUI.FUTURE,
      [EResidencyStateDB.NOTICE]: EResidencyStateUI.NOTICE,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EResidencyStateUI]: EResidencyStateDB;
    } = {
      [EResidencyStateUI.NONE]: EResidencyStateDB.NONE,
      [EResidencyStateUI.APPLICANT]: EResidencyStateDB.APPLICANT,
      [EResidencyStateUI.RESIDENT]: EResidencyStateDB.RESIDENT,
      [EResidencyStateUI.FUTURE]: EResidencyStateDB.FUTURE,
      [EResidencyStateUI.NOTICE]: EResidencyStateDB.NOTICE,
    };
    return statuses[data];
  },
};

export const tenantDetailsStatusConverter: TConvertor<
  ETenantDetailsStatusUI,
  ETenantDetailsStatusDB
> = {
  fromDb: data => {
    const statuses: {
      [key in ETenantDetailsStatusDB]: ETenantDetailsStatusUI;
    } = {
      [ETenantDetailsStatusDB.APPROVED]: ETenantDetailsStatusUI.APPROVED,
      [ETenantDetailsStatusDB.PENDING]: ETenantDetailsStatusUI.PENDING,
      [ETenantDetailsStatusDB.MOVED_IN]: ETenantDetailsStatusUI.MOVED_IN,
      [ETenantDetailsStatusDB.REJECTED]: ETenantDetailsStatusUI.REJECTED,
      [ETenantDetailsStatusDB.RETRACTED]: ETenantDetailsStatusUI.RETRACTED,
      [ETenantDetailsStatusDB.MOVED_OUT]: ETenantDetailsStatusUI.MOVED_OUT,
      [ETenantDetailsStatusDB.NOTICE]: ETenantDetailsStatusUI.NOTICE,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in ETenantDetailsStatusUI]: ETenantDetailsStatusDB;
    } = {
      [ETenantDetailsStatusUI.APPROVED]: ETenantDetailsStatusDB.APPROVED,
      [ETenantDetailsStatusUI.PENDING]: ETenantDetailsStatusDB.PENDING,
      [ETenantDetailsStatusUI.MOVED_IN]: ETenantDetailsStatusDB.MOVED_IN,
      [ETenantDetailsStatusUI.REJECTED]: ETenantDetailsStatusDB.REJECTED,
      [ETenantDetailsStatusUI.RETRACTED]: ETenantDetailsStatusDB.RETRACTED,
      [ETenantDetailsStatusUI.MOVED_OUT]: ETenantDetailsStatusDB.MOVED_OUT,
      [ETenantDetailsStatusUI.NOTICE]: ETenantDetailsStatusDB.NOTICE,
    };
    return statuses[data];
  },
};
export const petStatusConverter: TConvertor<EPetStatusUI, EPetStatusDB> = {
  fromDb: data => {
    const statuses: {
      [key in EPetStatusDB]: EPetStatusUI;
    } = {
      [EPetStatusDB.HAS_ANIMAL]: EPetStatusUI.HAS_ANIMAL,
      [EPetStatusDB.HAS_NOT_ANIMAL]: EPetStatusUI.HAS_NOT_ANIMAL,
      [EPetStatusDB.NOT_SPECIFIED]: EPetStatusUI.NOT_SPECIFIED,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: {
      [key in EPetStatusUI]: EPetStatusDB;
    } = {
      [EPetStatusUI.HAS_ANIMAL]: EPetStatusDB.HAS_ANIMAL,
      [EPetStatusUI.HAS_NOT_ANIMAL]: EPetStatusDB.HAS_NOT_ANIMAL,
      [EPetStatusUI.NOT_SPECIFIED]: EPetStatusDB.NOT_SPECIFIED,
    };
    return statuses[data];
  },
};

export const tenantInfoConverter: TFetchConverter<
  TTenantInfoUI,
  TTenantInfoDB
> = {
  fromDb: ({ tenantId, name, profilePicture }) => {
    return {
      tenantId,
      tenantName: name,
      profilePicture: profilePicture || '',
      firstLetters: getFirstLetters(name),
    };
  },
};

export const petInfoConverter: TFetchConverter<TPetInfoUI, TPetInfoDB> = {
  fromDb: ({ petStatus, petsCount }) => {
    return {
      petStatus: petStatusConverter.fromDb(petStatus),
      petsCount,
    };
  },
};

export const tenantConverter: TAuthConverter<
  { [key: string]: string | string[] } | undefined,
  { [key: string]: string | string[] },
  TTenantUI,
  TTenantDB
> = {
  fromDb: ({
    petStatus,
    petsCount,
    propertyManagementName,
    propertyManagementId,
    propertyManagementLogo,
    tenantId,
    notificationsEnabled,
    name,
    profilePicture,
    email,
    phone,
    isUsernameEmail,
    propertyName,
    propertyId,
    state,
    statusChangeInfo,
    unitNumber,
  }) => {
    return {
      tenantInfo: tenantInfoConverter.fromDb({
        tenantId,
        name,
        profilePicture,
      }),
      email,
      phone,
      isUsernameEmail,
      notificationsEnabled,
      propertyInfo: propertyInfoConverter.fromDb({ propertyId, propertyName }),
      propertyManagementInfo: propertyManagementInfoConverter.fromDb({
        propertyManagementId,
        propertyManagementName,
        propertyManagementLogo,
      }),
      petInfo: petInfoConverter.fromDb({ petStatus, petsCount }),
      residencyState: tenantResidencyStateConverter.fromDb(state),
      statusChangeInfo: statusChangeInfo
        ? statusInfoWithResourceConverter.fromDb(statusChangeInfo)
        : null,
      unitNumber,
    };
  },
  toDb: filter => filtersConverter.toDb(filter),
};

export const animalsConverter: TFetchConverter<
  TTenantAnimalDataUI,
  TTenantAnimalDataDB
> = {
  fromDb: data => {
    return {
      chargingStatusInfo: data.chargingStatusInfo
        ? statusInfoWithResourceConverter.fromDb(data.chargingStatusInfo)
        : null,
      canTagKnown: data.canTagKnown,
      profilePicture: data.profilePicture || undefined,
      animalId: data.animalId,
      isToPetConverted: data.isToPetConverted,
      breed: data?.breed ? data.breed?.value : '',
      chargingStatusChangeReason: data.chargingStatusChangeReason || '',
      id: data.id,
      name: data.name,
      chargingStatus: animalChargingStatusConverter.fromDb(data.chargingStatus),
      notBeingChargedReason: data.notBeingChargedReason
        ? notBeingChargedReasonConverter.fromDb(data.notBeingChargedReason)
        : null,
      specie: data.specie ? data.specie?.value : '',
      status: animalStatusConverter.fromDb(data.status),
      verificationStatus: verificationStatusConverter.fromDb(
        data.verificationStatus,
      ),
      type: animalTypesConvertor.fromDb(data.type),
      tags: (data.tags || []).map(item => tagConverter.fromDb(item)),
      discoveredTagReason: data.discoveredTagReason,
      policySignedBy: data.policySignedBy,
      policyDateSigned: data.policyDateSigned
        ? formattedDate(data.policyDateSigned)
        : 'N/A',
      policyName: data.policyName,
      policyContent: data.policyContent,
      signedDocType: data.signedDocType,
      policyId: data.policyId,
      statusChangeInfo: data.statusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.statusChangeInfo)
        : null,
    };
  },
};

export const residencyMembersConverter: TFetchConverter<
  TResidentDataUI,
  TResidentDataDB
> = {
  fromDb: data => {
    return {
      hasAnimal: data.hasAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      email: data.email || '',
      name: data.name,
      phone: data.phone || '',
      profilePicture: data.profilePicture || '',
      status: tenantDetailsStatusConverter.fromDb(data.status),
      animals: (data.animals || []).map(item => animalsConverter.fromDb(item)),
      id: data.id,
      leaseToDate: formattedDate(data.leaseToDate),
      moveInDate: formattedDate(data.moveInDate),
      nextLeaseToDate: data.nextLeaseToDate
        ? formattedDate(data.nextLeaseToDate)
        : null,
      tags: data.tags.map(item => tenantTagConverter.fromDb(item)),
      statusChangeInfo: data.statusChangeInfo
        ? statusInfoWithResourceConverter.fromDb(data.statusChangeInfo)
        : null,
    };
  },
};

export const tenantResidencyConverter: TFetchConverter<
  TResidencyDataUI,
  TResidencyDataDB
> = {
  fromDb: data => {
    let sa = 0;
    let esa = 0;
    let pet = 0;
    let shouldNotBeCharged = 0;
    let gracePeriod = 0;
    let beingCharged = 0;
    let notBeingCharged = 0;
    for (let i = 0; i < data.members.length; i += 1) {
      for (let j = 0; j < data.members[i]?.animals?.length; j += 1) {
        const animal = data.members[i]?.animals[j];
        if (animal?.type === EAnimalTypes.ESA) {
          esa += 1;
        } else if (animal?.type === EAnimalTypes.PET) {
          pet += 1;
        } else {
          sa += 1;
        }
        switch (animal?.chargingStatus) {
          case EAnimalPetChargeStatusDB.SHOULD_NOT_BE_CHARGE:
            shouldNotBeCharged += 1;
            break;
          case EAnimalPetChargeStatusDB.NOT_BEING_CHARGED:
            notBeingCharged += 1;
            break;
          case EAnimalPetChargeStatusDB.GRACE_PERIOD:
            gracePeriod += 1;
            break;
          case EAnimalPetChargeStatusDB.BEING_CHARGED:
            beingCharged += 1;
            break;
          default:
            break;
        }
      }
    }
    return {
      propertyName: data.propertyName || '',
      propertyManagementLogo: data.propertyManagementLogo || '',
      propertyManagementName: data.propertyManagementName || '',
      unitNumber: data.unitNumber || '',
      roommates: data.members?.length - 1,
      hasAnimal: data.hasAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      [EAnimalTypesUI.ESA]: esa,
      [EAnimalTypesUI.SA]: sa,
      [EAnimalTypesUI.PET]: pet,
      [EAnimalPetChargeStatusUI.SHOULD_NOT_BE_CHARGE]: shouldNotBeCharged,
      [EAnimalPetChargeStatusUI.NOT_BEING_CHARGED]: notBeingCharged,
      [EAnimalPetChargeStatusUI.GRACE_PERIOD]: gracePeriod,
      [EAnimalPetChargeStatusUI.BEING_CHARGED]: beingCharged,
      members: (data.members || []).map(item =>
        residencyMembersConverter.fromDb(item),
      ),
    };
  },
};

export const tenantDetailsConverter: TFetchConverter<
  TTenantDetailsUI,
  TTenantDetailsDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      email: data.email || '',
      phone: data.phone || '',
      notificationsEnabled: data.notificationsEnabled,
      residencies: (data.residencies || []).map(item =>
        tenantResidencyConverter.fromDb(item),
      ),
    };
  },
};

export const markTenantAsKnownConverter: TCreateConverter<
  TMarkTenantAsKnownUI,
  TMarkTenantAsKnownDB
> = {
  toDb: data => {
    return {
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};
