import { authSplitApi } from 'redux/helpers/slice.helpers';
import { materialOptionConverter } from 'data/convertors/General.converters';
import {
  TMultiOptionTypeMaterialUI,
  TOptionType,
} from 'data/types/GeneralTypes';
import { EAnimalUrls } from 'data/urls/animal.url';

export const generalApi = authSplitApi(
  'generalApi',
  ['species'],
  true,
).injectEndpoints({
  endpoints: build => ({
    getSpecies: build.query<TMultiOptionTypeMaterialUI[], void>({
      query() {
        return {
          url: `/${EAnimalUrls.ANIMALS}/${EAnimalUrls.SPECIES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TOptionType[]) =>
        (data || []).map(item => materialOptionConverter.fromDb(item)),
      providesTags: ['species'], // for caching and update cache
    }),
  }),
  overrideExisting: false,
});

export const { useGetSpeciesQuery } = generalApi;
