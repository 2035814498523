import {
  EAnimalTypes,
  EAnimalTypesUI,
  TAnimalDetailsGenderFieldUi,
} from './Animal.types';
import { TSADocPacketGet, TSADocPacketGetUI } from './AnimalTraining.types';
import {
  EApplicationStatus,
  EApplicationStatusUi,
  EGender,
  EValueEditingStates,
  EVerificationStatus,
  EVerificationStatusUi,
  TEditingFieldUi,
  TEditingYesNoFieldUI,
  TFail,
  TFailUI,
  TSignature,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
} from './GeneralTypes';
import {
  TApplicationVetRecords,
  TApplicationVetRecordsUI,
} from './VetRecord.types';
import { TDocPacketGet, TDocPacketGetUI } from './DocPacket.types';

export enum EApplicationStepName {
  'Property management' = 'Property management',
  SA = 'SA',
  ESA = 'ESA',
  PET = 'PET',
  'Tenant Profile' = 'Tenant Profile',
  Training = 'Training',
  Payment = 'Payment',
}

export enum EApplicationStepNameUi {
  'Property management' = 'Property management',
  SA = 'SA',
  ESA = 'ESA',
  PET = 'PET',
  'Tenant Profile' = 'Tenant Profile',
  Training = 'Training',
  Payment = 'Payment',
}

export enum EApplicationTypeDB {
  ESA = 'ESA',
  SA = 'SA',
  PET = 'PET',
}

export enum EApplicationTypeUI {
  ESA = 'ESA',
  SA = 'SA',
  PET = 'PET',
}

export enum EStatusChangerRoleDB {
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  ADMIN = 'ADMIN',
}

export enum EStatusChangerRoleUI {
  PROPERTY_MANAGER = 'property manager',
  ADMIN = 'OPP team',
}

export enum ESortFields {
  submittedDate = 'SUBMITTED_TIME',
  leaseStartDate = 'LEASE_START_DATE',
}

export type TApplicationDb = {
  id: string;
  leaseStartDate: string;
  submittedDate: string;
  applicationStatus: EApplicationStatus;
  statusChangerName: string | null;
  statusChangeInfo: TStatusChangeInfoDB | null;
  statusChangerOrgName: string | null;
  rejectReason: string;
  type: EApplicationTypeDB;
  tenantName: string;
  tenantPhoto: string | null;
  animalName: string;
  animalPhoto: string;
  animalSpecie: string;
  propertyManagementName: string;
  propertyManagementLogo: string | null;
  animalVerificationStatus: EVerificationStatus;
};

export type TApplicationUI = {
  id: string;
  leaseStartDate: string;
  submittedDate: string;
  status: EApplicationStatusUi;
  statusChangerName: string | null;
  statusChangerOrgName: string | null;
  statusChangeInfo: TStatusChangeInfoDB | null;
  rejectReason: string;
  type: EApplicationTypeUI;
  tenantName: string;
  tenantPhoto: string | null;
  animalName: string;
  animalPhoto: string;
  animalSpecie: string;
  propertyManagementName: string;
  propertyManagementLogo: string | null;
  animalVerificationStatus: EVerificationStatusUi;
};

export enum EApplicationStepTypeUi {
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  TENANT_PROFILE = 'TENANT_PROFILE',
  ANIMAL = 'ANIMAL',
  TRAINING = 'TRAINING',
  BILLING = 'BILLING',
}

export enum EApplicationStepType {
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  TENANT_PROFILE = 'TENANT_PROFILE',
  ANIMAL = 'ANIMAL',
  TRAINING = 'TRAINING',
  BILLING = 'BILLING',
}
export type TApplicationStep = {
  stepName: EApplicationStepName;
  stepType: EApplicationStepType;
  subtitle: string;
  stepImage: string;
  title: string;
  withWarnings?: boolean;
};

export type TApplicationStepUi = {
  stepName: EApplicationStepNameUi;
  stepType: EApplicationStepTypeUi;
  subTitle: string;
  stepImage?: string;
  title: string;
  withWarnings?: boolean;
};

export enum EApplicationAnimalStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EApplicationAnimalStatusUi {
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
}

export type TApplicationDetails = {
  id: string;
  type: EApplicationTypeDB;
  status: EApplicationStatus;
  statusChangeInfo: TStatusChangeInfoDB | null;
  applicationSteps: TApplicationStep[];
  animalVerificationStatus: EVerificationStatus;
  rejectReason: string;
};

export type TTApplicationDetailsUi = {
  id: string;
  type: EApplicationTypeUI;
  status: EApplicationStatusUi;
  statusChangeInfo: TStatusChangeInfoUI | null;
  applicationSteps: TApplicationStepUi[];
  animalVerificationStatus: EVerificationStatusUi;
  rejectReason: string;
};

export type TApplicationProppertyManagmentDB = {
  propertyManagementName: string;
  propertyManagementLogo: string | null;
  propertyManagerName: string;
  signature: TSignature | null;
  property: string;
  unit: string;
  leaseStartDate: string;
  leaseToDate: string | null;
};

export type TApplicationProppertyManagmentUI = {
  propertyManagementName: string;
  propertyManagementLogo: string | null;
  propertyManagerName: string;
  signature: TSignature | null;
  property: string;
  unit: string;
  leaseStartDate: string;
  leaseToDate: string | null;
};

export type TApplicationVerificationStatisticsDB = {
  saDocumentPacketCount: number;
  vetRecordCount: number;
  hpDocumentPacketCount: number;
  animalVerified: boolean;
  docsAreVerified: boolean;
};

export type TApplicationVerificationStatisticsUI = {
  saDocumentPacketCount: number;
  vetRecordCount: number;
  hpDocumentPacketCount: number;
  animalVerified: boolean;
  docsAreVerified: boolean;
};

export type TEditingWeightFieldUi = {
  value: string | null;
  unit: string | null;
  canEdit: boolean;
  canRemove: boolean;
};

export type TEditingWeightField = {
  value?: number | null;
  unit?: string | null;
  allowedOperations: EValueEditingStates[];
};

export type TAnimalDetailsGenderField = {
  value: EGender;
  allowedOperations: EValueEditingStates[];
};

export type TApplicationSADocPacketGetUI = TSADocPacketGetUI & {
  fails?: TFailUI[];
};
export type TApplicationSADocPacketGet = TSADocPacketGet & { fails?: TFail };

export type TApplicationAnimalUi = {
  id: string;
  name: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  species: TEditingFieldUi;
  breed: TEditingFieldUi;
  primaryColor: TEditingFieldUi;
  secondaryColor1: TEditingFieldUi;
  secondaryColor2: TEditingFieldUi;
  birthDate: TEditingFieldUi;
  weight: TEditingWeightFieldUi;
  isHouseTrained: TEditingYesNoFieldUI;
  gender: TAnimalDetailsGenderFieldUi;
  isReproductive: TEditingYesNoFieldUI;
  type: EAnimalTypesUI;
  fails: TFailUI[];
  documentsFails?: TFailUI[];
  vetRecordList: TApplicationVetRecordsUI[];
  packets: TApplicationSADocPacketGetUI[];
  status: EVerificationStatusUi;
  isDeleted: boolean;
};

export type TApplicationAnimal = {
  id: string;
  name: string;
  profilePicture: string;
  species: string;
  breed: string;
  primaryColor: string;
  secondaryColor1: string;
  secondaryColor2: string;
  birthDate: string | null;
  weight: TEditingWeightField;
  isHouseTrained: boolean;
  gender: EGender;
  isReproductive: boolean;
  type: EAnimalTypes;
  fails?: TFail;
  vetRecords: TApplicationVetRecords[] | null;
  saDocumentPackets: TApplicationSADocPacketGet[] | null;
  status: EVerificationStatus;
  isDeleted: boolean;
};
export type TApplicationHPPacketUI = TDocPacketGetUI & {
  fails?: TFailUI[];
};
export type TApplicationHPPacket = TDocPacketGet & {
  fails?: TFail;
};
