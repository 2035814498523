export enum EAnimalUrls {
  ANIMALS = 'animals',
  ANIMAL = 'animal',
  TAGS = 'tags',
  PNC = 'pnc',
  KNOWN = 'known',
  TYPE = 'type',
  EXPIRATION = 'expiration',
  ATTACH_VET_RECORD = 'attach-vet-record',
  VET_RECORDS = 'vet-records',
  VET_RECORD = 'vet-record',

  SA_DOC_PACKET = 'sa-doc-packet',
  DOCUMENTS = 'documents',
  VERIFY = 'verify',
  VIEW = 'view',
  WARNING = 'warning',
  CONVERSION = 'conversion',
  APPROVE = 'approve',
  REJECT = 'reject',
  FOLLOW_UP = 'follow-up',
  COMPLETE = 'complete',
  ITEM = 'item',
  RESTART = 'restart',
  CHARGING_STATUS = 'charging-status',
  DISCOVERED = 'discovered',
  APPLICANT = 'applicant',
  POLICY_CONTENT = 'policy-content',
  SPECIES = 'species',
}
