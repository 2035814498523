import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

const baseUrl = `${import.meta.env.REACT_APP_HOSTNAME}/admin/api/v2`;
const tenantUrl = `${import.meta.env.REACT_APP_HOSTNAME}/tenant/api/v2`;

export const authSplitApi = (
  reducerPath: string,
  tagTypes?: string[],
  tenantApi?: boolean,
) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: tenantApi ? tenantUrl : baseUrl,
      prepareHeaders: async headers => {
        try {
          const session = await Auth.currentSession();
          const idTokenExpire = session.getIdToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            try {
              const currentAuthUser = await Auth.currentAuthenticatedUser();
              currentAuthUser.refreshSession(refreshToken, (err: string) => {
                if (err) {
                  localStorage.clear();
                  window.location.replace(`/login`);
                } else {
                  headers.set(
                    'authorization',
                    `Bearer ${session.getIdToken().getJwtToken()}`,
                  );
                }
              });
            } catch (err) {
              localStorage.clear();
              window.location.replace(`/login`);
            }
          } else {
            headers.set(
              'authorization',
              `Bearer ${session.getIdToken().getJwtToken()}`,
            );
          }
        } catch (err) {
          localStorage.clear();
          window.location.replace(`/login`);
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
    }),
    endpoints: () => ({}),
  });
