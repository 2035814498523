import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EPolicyUrls } from 'data/urls/policy.url';
import { TSignatureType } from 'data/types/GeneralTypes';


const convertDocTypeToUrl = (docType?: TSignatureType | null) => {
  if (!docType) {
    return 'general';
  }
  if (docType === 'SERVICE_FORM') {
    return 'sa';
  }
  return docType.replace('_FORM', '').toLocaleLowerCase();
};

export const policyApi = authSplitApi('policy', ['esaDoc']).injectEndpoints({
  endpoints: build => ({
    getDocHtml: build.query<
      Blob,
      { signedDocType?: TSignatureType | null; policyId: string; tenantName: string }
    >({
      query({ signedDocType, policyId, tenantName }) {
        const url = convertDocTypeToUrl(signedDocType);
        return {
          url: `${EPolicyUrls.POLICY}/${EPolicyUrls.DOC}/${url}`,
          method: 'GET',
          params: { policyId, tenantName },
          responseHandler: async response => {
            const text = await response.text();
            return new Blob([text], { type: 'text/html' });
          },
        };
      },
    }),
  }),
});

export const { useGetDocHtmlQuery } = policyApi;
